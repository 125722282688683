/* eslint-disable no-undef */
import { UnsignedByteType } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

export function traverseMaterials(object, callback) {
    object.traverse((node) => {
        if (!node.isMesh) return;
        const materials = Array.isArray(node.material)
            ? node.material
            : [node.material];
        materials.forEach(callback);
    });
}

export function getCubeMapTexture(environment, pmremGenerator) {
    const { path } = environment;

    // no envmap
    if (!path) return Promise.resolve({ envMap: null });
        
    return new Promise((resolve, reject) => {

        new RGBELoader()
            .setDataType(UnsignedByteType)
            .load(path, (texture) => {

                const envMap = pmremGenerator.fromEquirectangular(texture).texture;
                pmremGenerator.dispose();

                resolve({ envMap });

            }, undefined, reject);

    });

}