// eslint-disable no-unused-vars
import React from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import TWEEN from '@tweenjs/tween.js';
TWEEN;
import { traverseMaterials, getCubeMapTexture } from './utils';
import { Header, SubHeader } from './styles';
// TODO: Convert this into a functional component.
class Scene extends React.Component {
  componentDidMount() {
    // Camera and Renderer setup
    let scene = new THREE.Scene();
    // scene.background = new THREE.Color(0x000000);

    scene.background = new THREE.Color(0xffffff);
    this.environment = {
      id: 'venice-sunset',
      name: 'Venice Sunset',
      path: '/venice_sunset_1k.hdr',
      format: '.hdr'
    },

      this.camera = new THREE.PerspectiveCamera(
        40,
        this.mount.offsetWidth / this.mount.offsetHeight,
        0.1,
        1000
      );
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.renderer.setClearColor( 0xcccccc );
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.mount.appendChild(this.renderer.domElement);
    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    getCubeMapTexture(this.environment, this.pmremGenerator).then(({ envMap }) => {
      scene.environment = envMap;
      // scene.background = this.state.background ? envMap : null;

    });

    // const controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.camera.position.z = 1.5;

    // controls.update();
    // OBJ setup
    const loader = new GLTFLoader();
    loader.load(
      '/robot/isaac_v35_Scene0.gltf', // called when the resource is loaded
      function (gltf) {
        gltf.scene.rotateY(2.7)
        gltf.scene.rotateZ(0.38);
        gltf.scene.rotateX(-0.6);
        gltf.scene.translateX(2.1);
        gltf.scene.translateZ(0.5);
        gltf.scene.translateY(-1.5);
        // gltf.scene.multiplyScalar(0.5);
        const encoding = THREE.sRGBEncoding;
        traverseMaterials(gltf.scene, (material) => {
          // console.log(material);
          if (material.map) material.map.encoding = encoding;
          if (material.emissiveMap) material.emissiveMap.encoding = encoding;
          if (material.map || material.emissiveMap) material.needsUpdate = true;
        });
        scene.add(gltf.scene);
      }
    );

    loader.load('/apartment/aprtmnt.gltf', function (gltf) {
      gltf.scene.rotateY(2.7);
      gltf.scene.rotateX(-0.1);
      gltf.scene.rotateZ(0.03);
      gltf.scene.translateX(-0.3);
      const scale = 0.6;
      gltf.scene.scale.multiplyScalar(scale);
      const encoding = THREE.sRGBEncoding;
      traverseMaterials(gltf.scene, (material) => {
        if (material.map) material.map.encoding = encoding;
        if (material.emissiveMap) material.emissiveMap.encoding = encoding;
        if (material.map || material.emissiveMap) material.needsUpdate = true;
      });
      scene.add(gltf.scene);
    })
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const onMouseMove = (event) => {

      // calculate mouse position in normalized device coordinates
      // (-1 to +1) for both components
      let canvasBounds = this.renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - canvasBounds.left) / (canvasBounds.right - canvasBounds.left)) * 2 - 1;
      mouse.y = - ((event.clientY - canvasBounds.top) / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;
      // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      // mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
      // console.log(mouse);
    }

    // this.renderer.domElement.addEventListener('mouseover', () => {
    //   // console.log('starting');
    //   position = { z: this.camera.position.z };
    //   target = { z: 1.4 };
    //   tween = new TWEEN.Tween(position).to(target, 3000);
    //   tween.easing(TWEEN.Easing.Quintic.Out);
    //   tween.onUpdate(() => {
    //     this.camera.position.z = position.z;
    //     // console.log('tweening in', position.z);
    //   });
    //   if (inverseTween) {
    //     inverseTween.stop();
    //   }

    //   tween.start();
    // });
    // this.renderer.domElement.addEventListener('mouseout', () => {
    //   // console.log('stopping');
    //   position.z = this.camera.position.z;
    //   target.z = 1.5;
    //   inverseTween = new TWEEN.Tween(position).to(target, 3000);
    //   inverseTween.easing(TWEEN.Easing.Quintic.Out);
    //   inverseTween.onUpdate(() => {
    //     this.camera.position.z = position.z;
    //     // console.log('tweening', position.z)
    //   })
    //   tween.stop();
    //   inverseTween.start();
    // })

    // Lighting Setup
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 1.3);
    // scene.add(directionalLight);
    // const light = new THREE.AmbientLight(0x9c9c9c); // soft white light
    // scene.add(light);

    // const light1  = new THREE.AmbientLight(0xFFFFFF, 0.15 * Math.PI);
    // light1.name = 'ambient_light';
    // this.camera.add( light1 );
    // scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xFFFFFF, 0.20 * Math.PI);
    light2.position.set(0.5, 0, 1.666); // ~60º
    light2.name = 'main_light';
    this.camera.add(light2);
    scene.add(light2);
    // console.log(scene);
    let currSelection;
    let tweenForward;
    let scale = 0.1;
    tweenForward;
    const getParent = (obj) => {
      if (obj.type === 'Group') return obj;
      return getParent(obj.parent);
    }
    let performanceElapsed = false;
    const processIntersects = (intersects) => {
      if (intersects.length === 0) {
        if (currSelection) {
          let position = new THREE.Vector3().copy(currSelection.position);
          let target = new THREE.Vector3().copy(position);
          target.z -= scale;

          console.log('removing hover', currSelection.uuid);
          
          let tweenb = new TWEEN.Tween(position).to(target, 1000);
          tweenb.easing(TWEEN.Easing.Quintic.Out);
          let modelToTween = currSelection;
          
          tweenb.onUpdate(() => {
            modelToTween.position.z = position.z;
            // console.log('tweening');
          })

          tweenb.start(); 
        }
         
        currSelection = null;
      }
      intersects.forEach(intersect => {
        let model = getParent(intersect.object);
        // console.log(model);
        if (!performanceElapsed) {
          if (performance.now() > 3000) {
            performanceElapsed = true
          }
        }
        if (!performanceElapsed) return;
        // //if we weren't hovered over this last frame (new hover event)
        // if (currSelection && model.uuid === currSelection.uuid) {
          
        // } 
        else if (!currSelection) {
          console.log('bringing x forward from nothing', model.uuid);

          currSelection = model;

          let position = new THREE.Vector3().copy(model.position);
          // console.log('origin', position)
          let target = new THREE.Vector3().copy(position);
          target.z += scale;
          // console.log('target', target);

          tweenForward = new TWEEN.Tween(position).to(target, 1000);
          tweenForward.easing(TWEEN.Easing.Quintic.Out);
          let modelToTween = currSelection;

          tweenForward.onUpdate(() => {
            modelToTween.position.z = position.z;
            // console.log('tweening');
          })
          tweenForward.start();
        }
        //return model uuid to generate new array 
        // return model.uuid
      });


      // //return any objects to rest if they aren't hovered
      // if (oldIntersects) {
      //   oldIntersects.forEach(model_uuid => {
      //     // if we aren't currently hovering an object, but was
      //     //hovering last frame.
      //     if (!currentIntersectIds.includes(model_uuid)) {
      //       // console.log('sending ', model_uuid, 'to rest')
      //       //do something to send object to rest
      //     }
      //   })
      // }

      // return currentIntersectIds;

    }
    processIntersects;

    // this.lights.push(light1, light2);
    // THREE boilerplate
    this.animate = function () {
      // controls.update();
      // eslint-disable-next-line no-undef
      // update the picking ray with the camera and mouse position
      raycaster.setFromCamera(mouse, this.camera);

      // calculate objects intersecting the picking ray
      const intersects = raycaster.intersectObjects(scene.children, true);
      // intersects;
      processIntersects(intersects);
      // currSelection = processIntersects(intersects);
      // // console.log(intersects);      
      // for (let i = 0; i < intersects.length; i++) {

      //   intersects[i].object.material.color.set(0xff0000);

      // }

      requestAnimationFrame(this.animate.bind(this));
      TWEEN.update();
      this.renderer.render(scene, this.camera);
    };

    this.animate();

    // eslint-disable-next-line no-undef
    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    window.addEventListener('mousemove', onMouseMove, false);

  }

  onWindowResize() {
    if (this.mount) {
      this.camera.aspect = this.mount.offsetWidth / this.mount.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight);
    }
  }

  render() {
    return (
      <div
        ref={(ref) => (this.mount = ref)}
        style={{ width: '675px', height: '450px', marginRight: '25px', position: 'relative' }}

      >
        <Header style={{ position: 'absolute', bottom: '40%', left: '25%' }}>housekeep</Header>
        <SubHeader style={{ position: 'absolute', bottom: '30px', left: '30px' }}>2 service robots</SubHeader>
        <SubHeader style={{ position: 'absolute', bottom: '30px', right: '30px' }}>(<u>55</u> apartment units)</SubHeader>
      </div>
    );
  }
}

export default Scene;
