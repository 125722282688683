import styled from 'styled-components'

export const Header = styled.p`
font-family: "Open Sans", sans-serif;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 36px;

/* Blue 1 */

color: #000000;
`


export const SubHeader = styled.p`
font-family: "Open Sans", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 20px;
color: #828282;

`